function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"signup-products"},[(_vm.editable)?_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrs = ref.errors;
return [_c('header',{staticClass:"signup-products__header"},[_c('div',{staticClass:"signup-products__errors"},[(!_vm.readOnly || _vm.productRegistrationErrors.length)?_c('grid-notifications',{attrs:{"errors":_vm.computedErrors,"uploaded":_vm.uploaded,"submitted":_vm.isRegistering,"signed-up":_vm.isRegistered,"promotion":_vm.promotion},on:{"error-click":function($event){return _vm.$modal.show('errors-modal')}}}):_vm._e(),(_vm.showErrorsOnlyFilter)?[_c('checkbox',{staticClass:"signup-products__checkbox",attrs:{"rounded":""},on:{"change":function($event){return _vm.$emit('change:only-errors-filter', _vm.errorsOnly)}},model:{value:(_vm.errorsOnly),callback:function ($$v) {_vm.errorsOnly=$$v},expression:"errorsOnly"}},[_vm._v(" Только ошибки ")])]:_vm._e()],2),(!_vm.readOnly)?_c('div',{staticClass:"signup-products__call-to-action"},[_c('x-btn',{attrs:{"block":"","disabled":_vm.hasFEValidationErrors(validationErrs),"loading":_vm.isSignLoading},on:{"click":function($event){return _vm.$modal.show('confirm')}}},[_vm._v(" Зарегистрировать список товаров в акцию ")])],1):_vm._e()]),(_vm.isSelfLoading)?_c('div',{staticClass:"signup-products__loader"},[_c('loader')],1):_c('grid',{staticClass:"signup-products__grid",attrs:{"auto":"","default-sort-by":"ali_id","unfoldable":"","actions-heading":"","error-description-field":"result_registry_error","columns":_vm.columns,"cells":_vm.unfoldedCells,"errors":validationErrs,"actions":_vm.actions},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var row = ref.row;
return [_c('product-meta',{staticClass:"signup-products__meta",attrs:{"product":row}})]}},{key:"unfold",fn:function(ref){
var row = ref.row;
return [_c('grid',{staticClass:"signup-products__unfold",attrs:{"auto":"","bordered":"","selectable":!_vm.readOnly && !_vm.isProductRegistered(row),"cells":row.offers,"columns":_vm.offerColumns,"checked-cells-ids":_vm.checkedProductsIds},on:{"cell-checked":function (cell) { return _vm.$emit('cell-checked', cell); },"check-all":function (value) { return _vm.$emit('check-all-row-offers', value, row.offers); }},scopedSlots:_vm._u([{key:"price",fn:function(ref){
var price = ref.price;
return [_c('span',{staticClass:"signup-products__price"},[_vm._v(" "+_vm._s(_vm.formatPrice(price ? price : 0))+" руб. ")])]}},{key:"editable",fn:function(ref){
var offer = ref.row;
var column = ref.column;
return [(_vm.readOnly)?_c('span',{style:(column.styles)},[_vm._v(" "+_vm._s(_vm.model[offer.id][column.key])+" ")]):[_c('x-form-item',{attrs:{"rules":_vm.getRules(offer, column),"name":column.name,"vid":((column.key) + "-" + (offer.id)),"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
var rest = objectWithoutProperties( ref, ["errors", "validate"] );
var validationData = rest;
return [_c('tooltip',{staticClass:"signup-products__tooltip",scopedSlots:_vm._u([(_vm.isProductRegistered(row))?{key:"popover",fn:function(){return [_vm._v(" Товар зарегистрирован, его нельзя изменить. Если вы хотите его изменить, обратитесь в тех. поддержку ")]},proxy:true}:(errors.length)?{key:"popover",fn:function(){return [_vm._v(" "+_vm._s(errors[0])+" ")]},proxy:true}:null],null,true)},[_c('x-input',_vm._b({staticClass:"form-field-box_viewtype_grid signup-products__field",attrs:{"id":((column.key) + "-" + (offer.id)),"size":"xs","name":column.key,"placeholder":column.name,"disabled":_vm.loading || _vm.isProductRegistered(row),"clearable":false},on:{"blur":function($event){return _vm.saveProductOffer(offer.id, column.key, _vm.model[offer.id][column.key], validate)}},model:{value:(_vm.model[offer.id][column.key]),callback:function ($$v) {_vm.$set(_vm.model[offer.id], column.key, $$v)},expression:"model[offer.id][column.key]"}},'x-input',validationData,false))],1)]}}],null,true)})]]}}],null,true)})]}},{key:"image",fn:function(ref){
var image = ref.image;
return [(image)?_c('img',{staticClass:"signup-products__image",attrs:{"src":image,"alt":"product"}}):_c('svg-icon',{staticClass:"signup-products__no-image",attrs:{"name":"no-image"}})]}},{key:"editable",fn:function(ref){
var row = ref.row;
var column = ref.column;
var visible = ref.visible;
return [(_vm.readOnly)?_c('span',{style:(column.styles)},[_vm._v(" "+_vm._s(_vm.model[row.id][column.key])+" ")]):(visible)?[_c('x-form-item',{attrs:{"rules":_vm.getRules(row, column),"name":column.name,"vid":((column.key) + "-" + (row.id)),"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
var rest = objectWithoutProperties( ref, ["errors", "validate"] );
var validationData = rest;
return [_c('tooltip',{staticClass:"signup-products__tooltip",scopedSlots:_vm._u([(_vm.isProductRegistered(row))?{key:"popover",fn:function(){return [_vm._v(" Товар зарегистрирован, его нельзя изменить. Если вы хотите его изменить, обратитесь в тех. поддержку ")]},proxy:true}:(errors.length)?{key:"popover",fn:function(){return [_vm._v(" "+_vm._s(errors[0])+" ")]},proxy:true}:null],null,true)},[_c('x-input',_vm._b({staticClass:"form-field-box_viewtype_grid signup-products__field",attrs:{"id":((column.key) + "-" + (row.id)),"size":"xs","name":column.key,"clearable":false,"placeholder":column.name,"disabled":_vm.loading || _vm.isProductRegistered(row)},on:{"blur":function($event){return _vm.saveProduct(row.id, column.key, _vm.model[row.id][column.key], validate)}},model:{value:(_vm.model[row.id][column.key]),callback:function ($$v) {_vm.$set(_vm.model[row.id], column.key, $$v)},expression:"model[row.id][column.key]"}},'x-input',validationData,false))],1)]}}],null,true)})]:[_c('x-input',{staticClass:"form-field-box_viewtype_grid signup-products__field",attrs:{"id":((column.key) + "-" + (row.id) + "-fake"),"name":column.key,"placeholder":column.name}})]]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('ul',{staticClass:"signup-products__actions"},[(_vm.showProductStatus || _vm.isProductRegistered(row))?_c('li',{staticClass:"signup-products__action"},[(row.status === 'READY')?_c('img',{staticClass:"signup-products__spinner",attrs:{"src":require("@/assets/images/spinner.gif")}}):_c('svg-icon',{staticClass:"signup-products__status",attrs:{"name":_vm.statusIcons[row.status]}})],1):_vm._e(),(!_vm.readOnly && !_vm.isProductRegistered(row))?_c('li',{staticClass:"signup-products__action"},[_c('button',{staticClass:"signup-products__remove",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('delete-product', [row.id])}}},[_c('svg-icon',{staticClass:"signup-products__remove-icon",attrs:{"name":"trash"}})],1)]):_vm._e()])]}}],null,false,410757224)})]}}],null,false,2843005630)}):[(_vm.isSelfLoading)?_c('div',{staticClass:"signup-products__loader"},[_c('loader')],1):_c('grid',{staticClass:"signup-products__grid",attrs:{"auto":"","default-sort-by":"ali_id","columns":_vm.columns,"cells":_vm.unfoldedCells,"unfoldable":""},scopedSlots:_vm._u([{key:"unfold",fn:function(ref){
var row = ref.row;
return [_c('grid',{staticClass:"signup-products__unfold_available",attrs:{"auto":"","bordered":"","cells":row.offers,"columns":_vm.availableOfferColumns},scopedSlots:_vm._u([{key:"price",fn:function(ref){
var price = ref.price;
return [_c('span',{staticClass:"signup-products__price"},[_vm._v(" "+_vm._s(_vm.formatPrice(price ? price : 0))+" руб. ")])]}},{key:"quantity",fn:function(ref){
var quantity = ref.quantity;
return [_vm._v(" "+_vm._s(quantity)+" шт ")]}}],null,true)})]}},{key:"image",fn:function(ref){
var image = ref.image;
return [(image)?_c('img',{staticClass:"signup-products__image",attrs:{"src":image,"alt":"product"}}):_c('svg-icon',{staticClass:"signup-products__no-image",attrs:{"name":"no-image"}})]}}])})],_c('confirm-modal',{on:{"decline":function($event){return _vm.$modal.hide('confirm')},"confirm":_vm.onSubmit},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v(" Действительно ли Вы хотите зарегистрировать товары в акцию? ")]},proxy:true}])}),_c('registration-failed-modal',{attrs:{"active":_vm.showWarnModal,"auth-status":_vm.shopAuthStatus,"shop-id":_vm.promotion.shop_id},on:{"change:active":function (active) { return _vm.showWarnModal = active; }}}),_c('two-fa-modal',{on:{"success":_vm.onShopAuthorize}}),_c('ali-auth-modal',{on:{"success":_vm.onShopAuthorize}}),_c('errors-modal',{attrs:{"enumerable":"","errors":_vm.computedErrors},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" При загрузке найдены ошибки. Исправьте их и перезагрузите товар заново: ")]},proxy:true}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
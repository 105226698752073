export default [{
  name: 'Фото',
  sortable: true,
  key: 'image_url',
  type: 'image'
}, {
  name: 'Ali ID',
  sortable: true,
  key: 'ali_id'
}, {
  name: 'Название',
  sortable: true,
  key: 'rus_name'
}]

export const availableOfferColumns = [{
  name: 'Артикул/штрих-код',
  sortable: false,
  key: 'external_id'
}, {
  name: 'Базовая цена',
  sortable: false,
  key: 'price_marketplace',
  type: 'price',
  styleType: 'numbers'
}, {
  name: 'Сток на маркетплейсе',
  sortable: false,
  key: 'stock_marketplace',
  type: 'quantity',
  styleType: 'numbers'
}]

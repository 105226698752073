export default {
  shop_registration_not_started: 'register_at',
  shop_registration_not_available: 'no_products',
  shop_registration_ready: 'sign',
  shop_registration_started: 'continue_sign',
  shop_registration_failed: 'continue_sign_failed',
  shop_registration_in_progress: 'registering',
  shop_registration_done: 'signed',
  shop_promo_is_participating: 'participating',
  shop_promo_skip: 'not_signed',
  shop_promo_done: 'participated',
  shop_promo_skipped: 'skipped'
}

<template>
  <div class="product-meta">
    <x-link
      v-if="pathName"
      class="product-meta__id product-meta__id_interactive"
      :metrics="orderMetrics.openOrder"
      :metrics-payload="{ order_id: order.order_id,
                          marketplace_code: order.marketplace.code,
                          shop_id: order.shop.id }"
      :to="routerLink"
    >
      {{ order.id }}
    </x-link>
    <div
      v-else
      class="product-meta__id"
    >
      {{ product.id }}
    </div>
    <slot>
      <div
        v-if="product.vendor_code"
        class="product-meta__text"
      >
        Артикул: {{ product.vendor_code }}
      </div>
      <div
        v-if="product.barcode"
        class="product-meta__text"
      >
        Штрихкод: {{ product.barcode }}
      </div>
    </slot>

    <template v-if="isOrder">
      <div
        v-if="order.gmt_create"
        class="product-meta__text"
      >
        Создан:
        <span class="product-meta__value">
          {{ formatDate(order.gmt_create, 'DD.MM.YYYY HH:mm') }}
        </span>
      </div>
      <div
        v-if="order.gmt_pay_time"
        class="product-meta__text"
      >
        Оплачен:
        <span class="product-meta__value">
          {{ formatDate(order.gmt_pay_time, 'DD.MM.YYYY HH:mm') }}
        </span>
      </div>

      <div class="product-meta__text">
        Отправлен: {{ formatDate(order.gmt_send_goods_time, 'DD.MM.YYYY HH:mm') || '-' }}
      </div>

      <div class="product-meta__text">
        Дата cпора: {{ formatDate(order.gmt_issue_time, 'DD.MM.YYYY HH:mm') || '-' }}
      </div>
    </template>
  </div>
</template>

<script>
import formatDate from '@/utils/date-formatter'

export default {
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    order: {
      type: Object,
      default: () => ({})
    },
    isOrder: {
      type: Boolean,
      default: false
    },
    pathName: {
      type: String,
      default: ''
    },
    pathParams: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    routerLink () {
      if (!this.pathName) return null

      return ({ name: this.pathName, params: this.pathParams })
    },

    orderMetrics () {
      return {
        openOrder: this.$route.meta?.metricsEvents?.orders?.openOrder
      }
    }
  },

  methods: {
    formatDate
  }
}
</script>

<style lang="stylus" scoped>
  .product-meta
    text-align left

    &__id
      margin-bottom 4px
      font-size 14px
      color #3c3c47
      transition color .3s

      &_interactive
        text-decoration underline

        &:hover
          color #4430bf

    &__text
      margin-bottom 4px
      font-size 12px
      color #9c9ca6

      &:last-child
        margin-bottom 0

    &__value
      color #545454
</style>

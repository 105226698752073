<template>
  <div class="promotion-signup">
    <header class="promotion-signup__header">
      <div class="promotion-signup__heading">
        <back-button
          :to="{name: 'promotion'}"
          class="promotion-signup__back"
          icon="back"
        />

        <heading class="promotion-signup__title">
          Регистрация в акции
        </heading>
      </div>
    </header>

    <promo-details-modal />

    <div
      v-if="isLoading"
      class="promotion-signup__loader"
    >
      <loader />
    </div>

    <template v-else>
      <promotion-card
        is-promo-signup
        :promotion="computedPromotion"
      />

      <instruction class="promotion-signup__instruction" />

      <signup-tabs
        class="promotion-signup__products"
        :available-products="availableProducts"
        :available-products-count="availableProductsCount"
        :products="products"
        :products-count="productsCount"
        :products-with-errors="productsWithErrors"
        :products-with-errors-count="productsWithErrorsCount"
        :is-loading="isGridLoading"
        @change:pagination="onPaginationChange"
        @change:only-errors-filter="onOnlyErrorsFilterChange"
      />
    </template>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Loader from '@/components/Common/Loader'
import Heading from '@/components/Common/Heading.vue'
import BackButton from '@/components/Interface/BackButton.vue'
import PromotionCard from '@/components/Promotion/PromotionCard.vue'
import Instruction from '@/components/Promotion/Instruction.vue'
import SignupTabs from '@/components/Promotion/SignupTabs.vue'
import PromoDetailsModal from '@/components/Modals/PromoDetailsModal.vue'

const { mapActions, mapState } = createNamespacedHelpers('promotions')

export default {
  components: {
    Loader,
    Heading,
    BackButton,
    PromotionCard,
    Instruction,
    SignupTabs,
    PromoDetailsModal
  },

  data () {
    return {
      isLoading: true,
      isGridLoading: false,
      fetchTimerId: null
    }
  },

  computed: {
    ...mapState([
      'promotion',
      'availableProducts',
      'availableProductsCount',
      'products',
      'productsCount',
      'productsWithErrors',
      'productsWithErrorsCount'
    ]),

    computedPromotion () {
      return this.promotion.id ? this.promotion : this.$route.params.promotion
    },

    id () {
      return this.$route.params.id
    }
  },

  async created () {
    this.isLoading = true

    await this.fetchPromotionData()
    this.setPromoFetchInterval()
    await this.getPromotionProductsErrors(this.id)

    this.isLoading = false
  },

  beforeDestroy () {
    clearInterval(this.fetchTimerId)
  },

  methods: {
    ...mapActions([
      'getPromotion',
      'getAvailableProducts',
      'getPromotionProducts',
      'getPromotionProductsErrors'
    ]),

    setPromoFetchInterval () {
      this.fetchTimerId = setInterval(this.fetchPromotionData, 10000)
    },

    async fetchPromotionData () {
      await Promise.all([
        this.getPromotion(this.id),
        this.getAvailableProducts({ id: this.id }),
        this.getPromotionProducts({ id: this.id })
      ])
    },

    async onPaginationChange ({ tabName, ...params }) {
      this.isGridLoading = true

      if (tabName === 'list') {
        await this.getAvailableProducts({ id: this.id, params })
      } else {
        await this.getPromotionProducts({ id: this.id, params })
      }

      this.isGridLoading = false
    },

    async onOnlyErrorsFilterChange (onlyErrors) {
      this.isGridLoading = true

      await this.getPromotionProducts({
        id: this.id,
        params: {
          only_errors: onlyErrors
        }
      })

      this.isGridLoading = false
    }
  }
}

</script>

<style lang="stylus" scoped>
  .promotion-signup
    &__heading
      display flex
      align-items center
      margin-bottom 30px

    &__title
      margin-bottom 0
      margin-left 25px

    &__loader
      padding 40px
      text-align center

    &__instruction
      margin 30px 0

    &__products
      margin-bottom 100px
</style>

<template>
  <div class="promo-instruction">
    <img
      class="promo-instruction__image"
      :src="thumbnail"
    >

    <h3 class="promo-instruction__title">
      {{ block.title }}
    </h3>

    <span class="promo-instruction__description">
      {{ block.description }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    thumbnail () {
      return require(`@/assets/images/promotion/${this.block.image}.svg`)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .promo-instruction
    height 100%
    box-sizing border-box
    display flex
    flex-direction column
    align-items center
    padding 30px
    background-color #fdfdfd
    box-shadow 0px 6px 16px rgba(5, 2, 55, 0.04)
    border-radius 8px

    &__image
      width 100px
      height 90px

    &__title
      padding-top 10px
      font-weight $font-weights.bold
      font-size 16px
      color #5b40ff

    &__description
      padding 12px 0
      text-align center
      font-size 14px
      color rgba(#2c2c37, 0.5)
</style>

<template>
  <div
    class="promotion-card-action"
    :class="actionClass"
  >
    <x-btn
      v-if="currentAction.isButton && (!isPromoSignup || promotion.is_demo)"
      type="button"
      block
      @click="currentAction.action"
    >
      {{ currentAction.text }}
    </x-btn>

    <component
      :is="component"
      :to="{ name: 'promotion.signup', params: { promotion, id: promotion.id } }"
      class="promotion-card-action__link"
    >
      <svg-icon
        v-if="currentAction.icon"
        :name="currentAction.icon"
        class="promotion-card-action__icon"
      />

      <template v-if="!currentAction.isButton">
        {{ currentAction.text }}
      </template>

      <template v-if="currentAction.strongText">
        {{ currentAction.strongText }}
      </template>

      <div
        v-if="currentAction.notification"
        class="promotion-card-action__notification"
      >
        <svg-icon
          :name="currentAction.notification.icon"
          class="promotion-card-action__icon"
        />
        {{ currentAction.notification.text }}
      </div>
    </component>
  </div>
</template>

<script>
import { getDiff } from '@/utils/date-formatter'
import { createNamespacedHelpers } from 'vuex'

const { mapActions } = createNamespacedHelpers('promotions')

export default {
  props: {
    action: {
      type: String,
      required: true,
      default: ''
    },
    promotion: {
      type: Object,
      default: () => ({})
    },
    isPromoSignup: Boolean
  },

  data () {
    return {
      actions: {
        enabled: {
          text: 'Доступно для регистрации',
          class: 'secondary'
        },
        sign: {
          text: 'Приступить к регистрации',
          isButton: true,
          action: this.startRegistration
        },
        continue_sign: {
          text: 'Продолжить регистрацию',
          isButton: true,
          action: this.goToPromoRegistration
        },
        continue_sign_failed: {
          text: 'Продолжить регистрацию',
          isButton: true,
          action: this.goToPromoRegistration,
          notification: { icon: 'error-triangle', text: 'При регистрации возникли ошибки' }
        },
        no_products: {
          text: 'Нет доступного товара',
          icon: 'info',
          class: 'danger'
        },
        signed: {
          text: 'Вы зарегистрированы',
          icon: 'bullet',
          class: 'primary'
        },
        not_signed: {
          text: 'Вы не зарегистрированы',
          class: 'secondary'
        },
        participating: {
          text: 'Вы участвуете',
          icon: 'bullet',
          class: 'primary'
        },
        participated: {
          text: 'Вы участвовали',
          icon: 'bullet',
          class: 'primary'
        },
        skipped: {
          text: 'Вы не участвовали',
          icon: 'sad',
          class: 'warning'
        },
        registering: {
          text: 'Идет регистрация товара',
          class: 'secondary'
        },
        register_at: {
          text: 'Регистрация через: ',
          strongText: this.getRegistrationStartDiff(),
          class: 'secondary'
        },
        demo: {
          text: this.isPromoSignup ? 'Подключить магазин' : 'Просмотреть пример',
          isButton: true,
          action: this.isPromoSignup ? this.goToPromoList : this.goToPromoRegistration
        }
      }
    }
  },

  computed: {
    currentAction () {
      if (this.promotion.is_demo) return this.actions.demo

      if (!this.action) return this.actions.enabled

      return this.actions[this.action]
    },

    actionClass () {
      if (!this.currentAction.class) return
      return `promotion-card-action_viewtype_${this.currentAction.class}`
    },

    component () {
      if (this.$route.name === 'promotion.signup') return 'span'

      return 'router-link'
    }
  },

  methods: {
    ...mapActions(['startPromoRegistration']),

    goToPromoRegistration () {
      this.$router.push({
        name: 'promotion.signup',
        params: {
          promotion: this.promotion,
          id: this.promotion.id
        }
      })
    },

    goToPromoList () {
      this.$router.push({ name: 'promotion' })
    },

    getRegistrationStartDiff () {
      const diff = getDiff(new Date(), this.promotion.registration_start_date)
      const days = Math.floor(diff.asDays())
      return `${days} дн ${diff.hours()} ч ${diff.minutes()} мин`
    },

    async startRegistration () {
      try {
        await this.startPromoRegistration(this.promotion.id)
      } catch (e) {
      }
      this.goToPromoRegistration()
    }
  }
}
</script>

<style lang="stylus" scoped>
  .promotion-card-action
    width 100%
    font-size 14px
    font-weight 600
    text-align center
    border-radius 4px

    &_viewtype_danger
      padding 9px 0
      background-color #fef6f4

    &_viewtype_primary
      padding 9px 0
      background-color #f4fcf5

    &_viewtype_warning
      padding 9px 0
      background-color #fefcf4

    &_viewtype_secondary
      padding 9px 0
      background-color #f6f6ff

    &__icon
      width 16px
      height 16px
      margin-right 8px

    >>> .svg-icon--info
      color #f03738

    >>> .svg-icon--bullet
      color #3cc13b

    >>> .svg-icon--error-triangle
      fill #f03738

    &__link
      display flex
      justify-content center
      align-items center
      flex-wrap wrap
      text-decoration none
      color inherit

    &__notification
      display flex
      align-items center
      font-size 12px
      font-weight 400
      color #f03738
</style>

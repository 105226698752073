<template>
  <div class="signup-buttons">
    <x-btn
      v-if="activeTab === 'list'"
      prepend="upload"
      class="signup-buttons__upload"
      @click="$emit('download')"
    >
      Скачать шаблон
    </x-btn>

    <div
      v-if="activeTab === 'signup' && counter"
      class="signup-buttons__group"
    >
      <file-upload
        accept=".xls, .xlsx"
        :uploaded="uploaded"
        @upload="$emit('upload', $event)"
      >
        <x-btn
          outline
          prepend="upload"
          class="signup-buttons__reload"
        >
          Перезагрузить товары
        </x-btn>
      </file-upload>

      <x-btn
        outline
        class="signup-buttons__delete"
        :loading="isDeleting"
        @click="$emit(hasCheckedProducts ? 'delete' : 'delete-all')"
      >
        {{ hasCheckedProducts ? 'Удалить' : 'Удалить всё' }}
      </x-btn>
    </div>
  </div>
</template>

<script>
import FileUpload from '@/components/Interface/FileUpload.vue'

export default {
  components: {
    FileUpload
  },

  props: {
    activeTab: {
      type: String,
      default: 'list'
    },
    counter: {
      type: Number,
      default: 0
    },
    uploaded: Boolean,
    hasCheckedProducts: Boolean,
    isDeleting: Boolean,
    isProductsUpdating: Boolean
  }
}
</script>

<style lang="stylus" scoped>
  .signup-buttons
    &__upload
      width 194px

    &__group
      display flex

    &__reload
      margin-right 10px
      width 200px
      pointer-events none

    &__delete
      width 122px
</style>

export default Object.freeze([{
  title: 'Доступные мне',
  path: 'available'
}, {
  title: 'В процессе регистрации',
  path: 'registration'
}, {
  title: 'Зарегистрирован',
  path: 'registered'
}, {
  title: 'Акция в процессе',
  path: 'in_progress'
}, {
  title: 'Завершена',
  path: 'finished'
}, {
  title: 'Все',
  path: 'all'
}])

<template>
  <div class="promotion">
    <header class="promotion__header">
      <promo-description />
    </header>

    <promo-details-modal />

    <div class="promotion__body">
      <heading level="2">
        Акции на AliExpress
      </heading>

      <tabs
        type="status"
        :tabs="tabs"
        :active="activeTab"
        class="promotion__tabs"
        :class="{'not-permit-by-tariff': !isPermitByTariff}"
        @click="onTabClick"
      />

      <promotion-filters
        class="promotion__filters"
        :class="{'not-permit-by-tariff': !isPermitByTariff}"
        :shop="shop"
        :sign-period="signPeriod"
        :promotion-period="promotionPeriod"
        :shop-status="shopStatus"
        @change:shop="onChangeShop"
        @change:sign-period="value => { signPeriod = value; onChangePeriod(value) }"
        @change:promotion-period="value => { promotionPeriod = value; onChangePeriod(value, 'promo_period') }"
        @change:shop-status="onChangeShopStatus"
        @clear="clearFilters"
      />

      <template v-if="isPermitByTariff">
        <transition-group name="tabs-fade">
          <div
            v-if="isLoading"
            key="loader"
            class="promotion__loader"
          >
            <loader />
          </div>

          <template v-else>
            <ul
              v-if="promotions.length && isPromotionAccessed"
              key="list"
              class="promotion__list"
            >
              <li
                v-if="showDemoCard"
                class="promotion__item"
              >
                <demo-card />
              </li>
              <li
                v-for="promotion in promotions"
                :key="promotion.id"
                class="promotion__item"
              >
                <promotion-card :promotion="promotion" />
              </li>
            </ul>

            <pagination
              v-if="promotions.length"
              key="pagination"
              :data="pagination"
              :current-page="currentPage"
              class="promotion__pagination"
              @change="onPaginationChange"
            />

            <div
              v-else
              key="placeholder"
            >
              <x-placeholder
                v-if="!isPromotionAccessed"
                icon="table-empty"
                desc="У вас нет ни одного магазина AliExpress с правами «Доступ к продвижению»"
              />

              <x-placeholder
                v-else-if="!aliShops.length"
                title="Данные не найдены"
                desc="Чтобы работать с системой, вам нужно добавить хотя бы один магазин AliExpress"
              >
                <template #bottom>
                  <x-btn @click="$modal.show('connect-promo-ali-modal')">
                    Добавить магазин
                  </x-btn>
                </template>
              </x-placeholder>

              <x-placeholder
                v-else-if="user.shop_count === 0 && isPromoUser && !hasAliAuthCompleted"
                title="К сожалению, у нас нет доступа для поиска подходящих для Вас акций"
                desc="Чтобы подобрать Вам акции, нам требуются логин и пароль от вашего кабинета AliExpress"
              >
                <template #bottom>
                  <x-btn @click="$modal.show('ali-auth-modal')">
                    Предоставить доступ
                  </x-btn>
                </template>
              </x-placeholder>

              <x-placeholder
                v-else-if="promotionsCount"
                icon="table-not-found"
                desc="Нет акций с таким фильтром"
              />
            </div>
          </template>
        </transition-group>
      </template>
      <not-permit-by-tariff v-else />
    </div>

    <connect-promo-ali-modal />
    <ali-auth-modal @success="fetchData" />
  </div>
</template>

<script>
import moment from 'moment'
import { createNamespacedHelpers } from 'vuex'
import { defaultFilterOption } from '@/constants/dashboards-filters'
import PromoDescription from '@/components/Promotion/Description.vue'
import Heading from '@/components/Common/Heading.vue'
import Loader from '@/components/Common/Loader.vue'
import Tabs from '@/components/Interface/Tabs.vue'
import PromotionFilters from '@/components/Promotion/PromotionFilters.vue'
import PromotionCard from '@/components/Promotion/PromotionCard.vue'
import PromoDetailsModal from '@/components/Modals/PromoDetailsModal.vue'
import ConnectPromoAliModal from '@/components/Modals/ConnectPromoAliModal.vue'
import AliAuthModal from '@/components/Modals/AliAuthModal.vue'
import Pagination from '@/components/Interface/Pagination.vue'
import DemoCard from '@/components/Promotion/DemoCard.vue'
import PaginationMixin from '@/mixins/pagination'
import tabs from '@/constants/promotion'
import '@/styles/transitions.styl'
import PERMISSION_KEY from '@/constants/PERMISSION_KEY'
import NotPermitByTariff from '@/components/NotPermit/NotPermitByTariff'

const { mapActions, mapState, mapGetters } = createNamespacedHelpers('promotions')
const { mapGetters: profileGetters, mapState: profileState } = createNamespacedHelpers('profile')
const { mapState: shopsState, mapActions: mapShopsActions } = createNamespacedHelpers('marketplaces')
const { mapActions: mapTrackingActions } = createNamespacedHelpers('tracking')

const emptyOption = {
  start: null,
  end: null
}

export default {
  mixins: [PaginationMixin],
  components: {
    NotPermitByTariff,
    Heading,
    PromoDescription,
    Loader,
    Tabs,
    PromotionFilters,
    PromotionCard,
    PromoDetailsModal,
    ConnectPromoAliModal,
    AliAuthModal,
    Pagination,
    DemoCard
  },

  data () {
    return {
      tabs,
      isLoading: false,
      shop: defaultFilterOption,
      signPeriod: emptyOption,
      promotionPeriod: emptyOption,
      shopStatus: { label: 'Все статусы', id: 'ALL' },
      filters: {
        shop_id: '',
        registration_period: '',
        promo_period: '',
        shop_status: '',
        tab_status: ''
      },
      promotionsCount: 0
    }
  },

  watch: {
    filters: {
      deep: true,
      handler () {
        if (this.currentPage === 1) {
          return this.fetchData()
        }
        this.currentPage = 1
      }
    }
  },

  computed: {
    ...mapState(['promotions']),
    ...mapGetters(['demoPromotion']),
    ...profileState(['user']),
    ...profileGetters([
      'isPromoUser',
      'hasAliAuthCompleted',
      'isUserAccessedToSection'
    ]),
    ...shopsState(['shops']),

    isPermitByTariff () {
      return this.$user.isPermitByTariff(PERMISSION_KEY.SELLER_PROMO)
    },

    activeTab () {
      return this.$route.query?.status || 'available'
    },

    aliShops () {
      return this.shops.filter(s => s.marketplace_code === 'ali')
    },

    showDemoCard () {
      const [aliShop] = this.aliShops
      return this.demoPromotion && this.aliShops.length === 1 && !aliShop?.is_enable_ali_access_data
    },

    isPromotionAccessed () {
      return this.aliShops.some(s => this.isUserAccessedToSection({
        section: 'access_promotion',
        shopId: s.id
      }))
    }
  },

  created () {
    Promise.all([
      this.getShopList({ marketplace_code: 'ali' }),
      this.fetchAllPromotions(),
      this.fetchData()
    ])
  },

  methods: {
    ...mapActions(['getPromotions']),
    ...mapShopsActions(['getShopList']),
    ...mapTrackingActions(['setEvent']),

    async fetchData (pagination = {}) {
      try {
        this.isLoading = true

        let payload = Object
          .keys(this.filters)
          .filter(key => this.filters[key] && this.filters[key] !== defaultFilterOption.id)
          .reduce((acc, key) => {
            acc[key] = this.filters[key]

            return acc
          }, {})

        payload = {
          params: {
            page: this.currentPage,
            ...this.pagination,
            ...payload,
            ...pagination,
            tab_status: this.activeTab !== 'all' ? this.activeTab : null
          }
        }

        this.pagination.count = await this.getPromotions(payload)
      } finally {
        this.isLoading = false
      }
    },

    async fetchAllPromotions () {
      this.promotionsCount = await this.getPromotions({
        store: false,
        params: { limit: 1 }
      })
    },

    onChangePeriod (date, period = 'registration_period') {
      if (!date.start || !date.end) {
        this.filters[period] = ''
        return
      }

      if (date.start === date.end) {
        const end = moment(date.start).add(1, 'days').format('YYYY-MM-DD')

        this.filters[period] = `${date.start}_${end}`

        return
      }

      this.filters[period] = `${date.start}_${date.end}`
    },

    onChangeShop (option) {
      this.shop = option
      this.filters.shop_id = option.id
    },

    onChangeShopStatus (option) {
      this.shopStatus = option

      if (option.id !== 'ALL') {
        this.filters.shop_status = option.id
      } else {
        this.filters.shop_status = ''
      }
    },

    clearFilters () {
      this.shop = defaultFilterOption
      this.signPeriod = emptyOption
      this.promotionPeriod = emptyOption
      this.shopStatus = ''
      this.filters = {
        ...this.filters,
        shop_id: '',
        registration_period: '',
        promo_period: '',
        shop_status: ''
      }
    },

    onPaginationChange ({ page, limit, offset }) {
      this.currentPage = page

      this.pagination.limit = limit

      this.fetchData({ page, limit, offset })
    },

    onTabClick (status) {
      this.filters.tab_status = status
      if (status === 'all') {
        this.setEvent({
          name: 'mmp_promotion_click_all',
          product: 'MMP',
          screen: 'mmp_promotion'
        })
      } else if (status === 'registration') {
        this.setEvent({
          name: 'mmp_promotion_click_registration',
          product: 'MMP',
          screen: 'mmp_promotion'
        })
      } else if (status === 'registered') {
        this.setEvent({
          name: 'mmp_promotion_click_registered',
          product: 'MMP',
          screen: 'mmp_promotion'
        })
      } else if (status === 'in progress') {
        this.setEvent({
          name: 'mmp_promotion_click_inprogress',
          product: 'MMP',
          screen: 'mmp_promotion'
        })
      } else if (status === 'finished') {
        this.setEvent({
          name: 'mmp_promotion_click_finished',
          product: 'MMP',
          screen: 'mmp_promotion'
        })
      } else if (status === 'available') {
        this.setEvent({
          name: 'mmp_promotion_click_available',
          product: 'MMP',
          screen: 'mmp_promotion'
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .promotion
    padding-bottom 90px

    &__header
      margin-bottom 30px

    &__loader
      padding 40px
      text-align center

    &__tabs
      margin-bottom 20px

    &__filters
      margin-bottom 30px

    &__pagination
      margin-top 44px

    &__list
      margin 0
      padding 0
      list-style none

    &__item
      margin-bottom 30px

      &:last-child
        margin-bottom 0
</style>

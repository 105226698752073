<template>
  <description-blocks
    :blocks="descriptionBlocks"
    expandable
    identity="promo"
    class="promo-description"
  >
    <template #title>
      <heading class="promo-description__heading">
        Продвижение
      </heading>
    </template>
  </description-blocks>
</template>

<script>
import DescriptionBlocks from '@/components/Interface/Description.vue'
import Heading from '@/components/Common/Heading.vue'

export default {
  components: {
    DescriptionBlocks,
    Heading
  },

  props: {
    autopromoEnabled: Boolean
  },

  data () {
    return {
      descriptionBlocks: [{
        img: require('@/assets/images/svg/promotion-1.svg'),
        title: '1. Все акции AliExpress в одном месте',
        text: 'Больше не нужно переключаться между магазинами, работайте со всеми акциями в одном окне'
      }, {
        img: require('@/assets/images/svg/promotion-2.svg'),
        title: '2. Только подходящие товары',
        text: 'Система автоматически фильтрует и предлагает только подходящие для данной акции товары'
      }, {
        img: require('@/assets/images/svg/promotion-3.svg'),
        title: '3. Массовая регистрация товаров',
        text: 'Больше не нужно добавлять товары в акции по одному, просто укажите нужные скидки и стоки и зарегистрируйте товары в акции'
      }]
    }
  }
}
</script>

<style lang="stylus" scoped>
  .promo-description
    &__heading
      margin-bottom 0
      line-height 1
</style>

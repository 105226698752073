<template>
  <div class="signup-products">
    <validation-observer
      v-if="editable"
      ref="form"
      v-slot="{ errors: validationErrs }"
    >
      <header class="signup-products__header">
        <div class="signup-products__errors">
          <grid-notifications
            v-if="!readOnly || productRegistrationErrors.length"
            :errors="computedErrors"
            :uploaded="uploaded"
            :submitted="isRegistering"
            :signed-up="isRegistered"
            :promotion="promotion"
            @error-click="$modal.show('errors-modal')"
          />
          <template v-if="showErrorsOnlyFilter">
            <checkbox
              v-model="errorsOnly"
              rounded
              class="signup-products__checkbox"
              @change="$emit('change:only-errors-filter', errorsOnly)"
            >
              Только ошибки
            </checkbox>
          </template>
        </div>

        <div
          v-if="!readOnly"
          class="signup-products__call-to-action"
        >
          <x-btn
            block
            :disabled="hasFEValidationErrors(validationErrs)"
            :loading="isSignLoading"
            @click="$modal.show('confirm')"
          >
            Зарегистрировать список товаров в акцию
          </x-btn>
        </div>
      </header>

      <div
        v-if="isSelfLoading"
        class="signup-products__loader"
      >
        <loader />
      </div>

      <grid
        v-else
        class="signup-products__grid"
        auto
        default-sort-by="ali_id"
        unfoldable
        actions-heading=""
        error-description-field="result_registry_error"
        :columns="columns"
        :cells="unfoldedCells"
        :errors="validationErrs"
        :actions="actions"
      >
        <template #id="{ row }">
          <product-meta
            :product="row"
            class="signup-products__meta"
          />
        </template>

        <template #unfold="{ row }">
          <grid
            auto
            bordered
            :selectable="!readOnly && !isProductRegistered(row)"
            :cells="row.offers"
            :columns="offerColumns"
            class="signup-products__unfold"
            :checked-cells-ids="checkedProductsIds"
            @cell-checked="cell => $emit('cell-checked', cell)"
            @check-all="value => $emit('check-all-row-offers', value, row.offers)"
          >
            <template #price="{ price }">
              <span class="signup-products__price">
                {{ formatPrice(price ? price : 0) }} руб.
              </span>
            </template>

            <template #editable="{ row: offer, column }">
              <span
                v-if="readOnly"
                :style="column.styles"
              >
                {{ model[offer.id][column.key] }}
              </span>

              <template v-else>
                <x-form-item
                  v-slot="{ errors, validate, ...validationData }"
                  :rules="getRules(offer, column)"
                  :name="column.name"
                  :vid="`${column.key}-${offer.id}`"
                  mode="aggressive"
                >
                  <tooltip
                    class="signup-products__tooltip"
                  >
                    <x-input
                      :id="`${column.key}-${offer.id}`"
                      v-model="model[offer.id][column.key]"
                      v-bind="validationData"
                      size="xs"
                      :name="column.key"
                      :placeholder="column.name"
                      :disabled="loading || isProductRegistered(row)"
                      :clearable="false"
                      class="form-field-box_viewtype_grid signup-products__field"
                      @blur="saveProductOffer(offer.id, column.key, model[offer.id][column.key], validate)"
                    />

                    <template
                      v-if="isProductRegistered(row)"
                      #popover
                    >
                      Товар зарегистрирован, его нельзя изменить. Если вы хотите его изменить, обратитесь в тех. поддержку
                    </template>

                    <template
                      v-else-if="errors.length"
                      #popover
                    >
                      {{ errors[0] }}
                    </template>
                  </tooltip>
                </x-form-item>
              </template>
            </template>
          </grid>
        </template>

        <template #image="{ image }">
          <img
            v-if="image"
            :src="image"
            alt="product"
            class="signup-products__image"
          >
          <svg-icon
            v-else
            name="no-image"
            class="signup-products__no-image"
          />
        </template>

        <template #editable="{ row, column, visible }">
          <span
            v-if="readOnly"
            :style="column.styles"
          >
            {{ model[row.id][column.key] }}
          </span>

          <template v-else-if="visible">
            <x-form-item
              v-slot="{ errors, validate, ...validationData }"
              :rules="getRules(row, column)"
              :name="column.name"
              :vid="`${column.key}-${row.id}`"
              mode="aggressive"
            >
              <tooltip
                class="signup-products__tooltip"
              >
                <x-input
                  :id="`${column.key}-${row.id}`"
                  v-model="model[row.id][column.key]"
                  v-bind="validationData"
                  size="xs"
                  :name="column.key"
                  :clearable="false"
                  :placeholder="column.name"
                  :disabled="loading || isProductRegistered(row)"
                  class="form-field-box_viewtype_grid signup-products__field"
                  @blur="saveProduct(row.id, column.key, model[row.id][column.key], validate)"
                />

                <template
                  v-if="isProductRegistered(row)"
                  #popover
                >
                  Товар зарегистрирован, его нельзя изменить. Если вы хотите его изменить, обратитесь в тех. поддержку
                </template>

                <template
                  v-else-if="errors.length"
                  #popover
                >
                  {{ errors[0] }}
                </template>
              </tooltip>
            </x-form-item>
          </template>

          <template v-else>
            <x-input
              :id="`${column.key}-${row.id}-fake`"
              class="form-field-box_viewtype_grid signup-products__field"
              :name="column.key"
              :placeholder="column.name"
            />
          </template>
        </template>

        <template #actions="{ row }">
          <ul class="signup-products__actions">
            <li
              v-if="showProductStatus || isProductRegistered(row)"
              class="signup-products__action"
            >
              <img
                v-if="row.status === 'READY'"
                :src="require(`@/assets/images/spinner.gif`)"
                class="signup-products__spinner"
              >
              <svg-icon
                v-else
                :name="statusIcons[row.status]"
                class="signup-products__status"
              />
            </li>

            <li
              v-if="!readOnly && !isProductRegistered(row)"
              class="signup-products__action"
            >
              <button
                class="signup-products__remove"
                type="button"
                @click="$emit('delete-product', [row.id])"
              >
                <svg-icon
                  class="signup-products__remove-icon"
                  name="trash"
                />
              </button>
            </li>
          </ul>
        </template>
      </grid>
    </validation-observer>

    <template v-else>
      <div
        v-if="isSelfLoading"
        class="signup-products__loader"
      >
        <loader />
      </div>

      <grid
        v-else
        auto
        default-sort-by="ali_id"
        :columns="columns"
        :cells="unfoldedCells"
        unfoldable
        class="signup-products__grid"
      >
        <template #unfold="{ row }">
          <grid
            auto
            bordered
            :cells="row.offers"
            :columns="availableOfferColumns"
            class="signup-products__unfold_available"
          >
            <template #price="{ price }">
              <span class="signup-products__price">
                {{ formatPrice(price ? price : 0) }} руб.
              </span>
            </template>

            <template #quantity="{ quantity }">
              {{ quantity }} шт
            </template>
          </grid>
        </template>

        <template #image="{ image }">
          <img
            v-if="image"
            :src="image"
            alt="product"
            class="signup-products__image"
          >
          <svg-icon
            v-else
            name="no-image"
            class="signup-products__no-image"
          />
        </template>
      </grid>
    </template>

    <confirm-modal
      @decline="$modal.hide('confirm')"
      @confirm="onSubmit"
    >
      <template #text>
        Действительно ли Вы хотите зарегистрировать товары в акцию?
      </template>
    </confirm-modal>

    <registration-failed-modal
      :active="showWarnModal"
      :auth-status="shopAuthStatus"
      :shop-id="promotion.shop_id"
      @change:active="active => showWarnModal = active"
    />

    <two-fa-modal @success="onShopAuthorize" />

    <ali-auth-modal @success="onShopAuthorize" />

    <errors-modal
      enumerable
      :errors="computedErrors"
    >
      <template #title>
        При загрузке найдены ошибки. Исправьте их и перезагрузите товар заново:
      </template>
    </errors-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Checkbox from '@/components/Interface/Checkbox.vue'
import GridNotifications from '@/components/Interface/GridNotifications.vue'
import Grid from '@/components/Interface/Grid.vue'
import ProductMeta from '@/components/Common/ProductMeta.vue'
import ConfirmModal from '@/components/Common/ConfirmModal.vue'
import Loader from '@/components/Common/Loader.vue'
import Tooltip from '@/components/Common/Tooltip.vue'
import RegistrationFailedModal from '@/components/Promotion/Modals/RegistrationFailedModal.vue'
import AliAuthModal from '@/components/Modals/AliAuthModal.vue'
import TwoFaModal from '@/components/Modals/2FAModal.vue'
import ErrorsModal from '@/components/Modals/ErrorsModal.vue'
import formatPrice from '@/utils/price-formatter'
import { offerColumns } from '@/constants/sign-products-editable'
import { availableOfferColumns } from '@/constants/sign-product'
import { readOnlyStatuses, registeredStatuses } from '@/constants/promotion-statuses.js'
import { authStatuses } from '@/constants/shops'

const { mapActions, mapState } = createNamespacedHelpers('promotions')

export default {
  components: {
    Checkbox,
    GridNotifications,
    Grid,
    ProductMeta,
    ConfirmModal,
    Loader,
    Tooltip,
    RegistrationFailedModal,
    AliAuthModal,
    TwoFaModal,
    ErrorsModal
  },

  props: {
    cells: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    promotion: {
      type: Object,
      default: () => ({})
    },
    checkedProductsIds: {
      type: Array,
      default: () => []
    },
    editable: Boolean,
    uploaded: Boolean,
    isLoading: Boolean
  },

  data () {
    return {
      offerColumns,
      availableOfferColumns,
      model: {},
      loading: false,
      actions: ['remove'],
      isSelfLoading: this.isLoading,
      isSignLoading: false,
      errorsOnly: false,
      showWarnModal: false,
      statusIcons: {
        'OK': 'bullet',
        'ERROR': 'exclamation'
      }
    }
  },

  watch: {
    editable: {
      handler (isEditable) {
        if (isEditable) {
          this.prepareModel()
        }
      },
      immediate: true
    },

    async cells () {
      if (this.editable) {
        this.prepareModel()
        await this.$nextTick()
        this.$refs.form.validate()
      }
    },

    async isLoading () {
      this.isSelfLoading = this.isLoading

      if (!this.isSelfLoading && this.editable) {
        await this.$nextTick()
        this.$refs.form.validate()
      }
    },

    async uploaded () {
      await this.setBackendErrors()
    }
  },

  computed: {
    ...mapState(['errors', 'productRegistrationErrors']),

    id () {
      return this.promotion.id
    },

    shopStatus () {
      return this.promotion.shop_status_detail
    },

    isRegistering () {
      return this.shopStatus === 'shop_registration_in_progress'
    },

    isRegistered () {
      return this.shopStatus === 'shop_registration_done'
    },

    shopAuthStatus () {
      return this.promotion.shop_has_credentials
        ? this.promotion.shop_authorization_status
        : 'not_authorized'
    },

    readOnly () {
      if (this.promotion.is_demo) return
      return readOnlyStatuses.includes(this.shopStatus)
    },

    unfoldedCells () {
      return this.cells.map(c => ({ ...c, isUnfold: true }))
    },

    computedErrors () {
      const regErrors = this.productRegistrationErrors
      return regErrors.length ? regErrors : this.errors
    },

    showErrorsOnlyFilter () {
      const regErrors = this.productRegistrationErrors
      return this.errorsOnly || regErrors.length || (!this.readOnly && this.errors.length)
    },

    showProductStatus () {
      const hasRegErrors = this.productRegistrationErrors.length
      return hasRegErrors || registeredStatuses.includes(this.promotion.shop_status_detail)
    }
  },

  methods: {
    ...mapActions([
      'getPromotionProductsErrors',
      'savePromotionProduct',
      'savePromotionOffer',
      'registerProductsOnPromo',
      'getPromotion'
    ]),

    formatPrice,

    hasFEValidationErrors (errors) {
      return Object.keys(errors).some(key => errors[key].length)
    },

    prepareModel (cells) {
      // TODO: Remove magic string
      this.model = {}
      const editableColumns = [
        ...this.columns.filter(c => c.type === 'editable'),
        ...this.offerColumns.filter(c => c.type === 'editable')
      ]

      editableColumns.forEach(column => {
        this.cells.forEach(c => {
          this.model[c.id] = {
            ...this.model[c.id],
            [column.key]: c[column.key] ?? null
          }
          c.offers.forEach(offer => {
            this.model[offer.id] = {
              ...this.model[offer.id],
              [column.key]: offer[column.key] ?? null
            }
          })
        })
      })
    },

    getRules (row, column) {
      switch (column.key) {
        case 'quantity':
          return `${column.validationRules}|between:1,${row.quantity_in_promo}`

        case 'discount_mob':
          return `${column.validationRules}|discount_mob:${row.discount + 1},99`

        default:
          return column.validationRules
      }
    },

    isProductRegistered (product) {
      return product.status === 'OK'
    },

    checkRegistrationIsAvailable () {
      if (this.promotion.is_demo) {
        this.$toast.warning('Данный функционал недоступен в режиме демо-акции')
        return
      }

      if (!this.promotion.shop_has_credentials) {
        this.showWarnModal = true
        return
      }

      const status = this.promotion.shop_authorization_status
      if (status === authStatuses.wrongPassword) {
        this.showWarnModal = true
        return
      }

      if (status === authStatuses.requires2FA) {
        this.$modal.show('2fa-modal', { id: this.promotion.shop_id })
        return
      }

      return true
    },

    async onShopAuthorize () {
      this.showWarnModal = false
      await this.getPromotion(this.id)
      this.onSubmit()
    },

    async setBackendErrors () {
      await this.getPromotionProductsErrors(this.id)
      await this.$nextTick()
      this.$refs.form.validate()
    },

    async onSubmit () {
      try {
        this.$modal.hide('confirm')
        this.isSignLoading = true
        this.showWarnModal = false

        if (!this.checkRegistrationIsAvailable()) {
          return
        }

        await this.setBackendErrors()

        if (!this.errors.length) {
          await this.registerProductsOnPromo(this.id)
          await this.getPromotion(this.id)
          await this.getPromotionProductsErrors(this.id)
        }
      } finally {
        this.isSignLoading = false
      }
    },

    async saveProduct (productId, field, value, validate) {
      try {
        const { errors } = await validate()

        if (errors.length) return

        this.loading = true

        await this.savePromotionProduct({
          promoId: this.id,
          id: productId,
          [field]: Number(value)
        })

        await this.setBackendErrors()
      } finally {
        this.loading = false
      }
    },

    async saveProductOffer (offerId, field, value, validate) {
      try {
        const { errors } = await validate()

        if (errors.length) return

        this.loading = true

        await this.savePromotionOffer({
          promoId: this.id,
          id: offerId,
          [field]: Number(value)
        })

        await this.setBackendErrors()
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .signup-products
    &__header
      display flex

    &__checkbox
      margin-top 20px

    &__meta
      min-width 140px

    &__image
      max-width 75px

    &__no-image
      width 68px
      height 68px

    &__loader
      padding 40px
      text-align center

    &__call-to-action
      flex-shrink 0
      flex-grow 0
      flex-basis 334px
      margin-left 20px
      margin-bottom 26px

    &__errors
      flex-grow 1
      font-size 14px
      margin-bottom 26px

    &__actions
      display flex
      align-items center
      margin 0
      margin-left -15px
      padding 0
      list-style none

    &__action
      margin-left 15px
      text-align center

    &__spinner
      width 26px
      height 26px

    &__status
      width 16px
      height 16px
      color #3cc13b

    &__remove
      width 14px
      height 14px

    &__remove-icon
      width 14px
      height 14px

    &__field
      width 140px

      >>> input
        text-align right
        font-family "Arial"
        font-size 14px
        color #3c3c47

    &__unfold
      padding-bottom 25px

      &_available
        box-sizing border-box
        padding 0 90px 25px

    &__price
      display block
      min-width 142px
      padding-top 3px
      font-size 14px
      color #3c3c47
</style>

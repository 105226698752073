<template>
  <warn-modal
    :action="currentStatus.action"
    class="warn-modal"
    @click="onWarnClick"
    @close="$emit('change:active', false)"
  >
    <template #icon>
      <svg-icon
        :name="currentStatus.icon"
        class="warn-modal__icon"
      />
    </template>

    <template #title>
      {{ currentStatus.title }}
    </template>

    {{ currentStatus.text }}
  </warn-modal>
</template>

<script>
import WarnModal from '@/components/Modals/WarnModal.vue'
import { shopAuthStatuses } from '@/constants/promotion-statuses'

export default {
  components: {
    WarnModal
  },

  props: {
    active: Boolean,
    shopId: {
      type: [String, Number],
      default: ''
    },
    authStatus: {
      type: String,
      default: ''
    }
  },

  watch: {
    active (isActive) {
      isActive ? this.$modal.show('warn-modal') : this.$modal.hide('warn-modal')
    }
  },

  computed: {
    currentStatus () {
      return shopAuthStatuses[this.authStatus] || {}
    }
  },

  methods: {
    onWarnClick () {
      this.$modal.show('ali-auth-modal', { id: this.shopId })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .warn-modal
    &__icon
      margin-bottom 21px
      width 70px
      height 70px

      &.svg-icon--error-triangle
        fill #f03738

      &.svg-icon--info
        color #f3bb1c
</style>

<template>
  <modal
    :name="modalName"
    :max-width="540"
    :min-width="280"
    width="90%"
    height="auto"
    :adaptive="true"
    @closed="onClosed"
  >
    <div class="modal-container">
      <close-button
        @click="$modal.hide(modalName)"
      />

      <connect-form-ali
        v-if="!isFormSended"
        :is-loading="isLoading"
        @submit="onSubmit"
      >
        <template #heading>
          Еще пара шагов&hellip;
        </template>

        <template #text>
          Подключите свой магазин для работы с ним в личном кабинете.
        </template>
      </connect-form-ali>

      <success-connection
        v-if="isFormSended"
        :text="successText"
        @click="onSuccessClick"
      />
    </div>
  </modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import ConnectFormAli from '@/components/Marketplaces/ConnectForms/ConnectFormAliexpress.vue'
import SuccessConnection from '@/components/Marketplaces/ConnectForms/SuccessConnection.vue'
import CloseButton from '@/components/Interface/CloseButton.vue'

const { mapActions } = createNamespacedHelpers('marketplaces')

export default {
  components: {
    ConnectFormAli,
    SuccessConnection,
    CloseButton
  },

  data () {
    return {
      modalName: 'connect-promo-ali-modal',
      isFormSended: false,
      isLoading: false,
      successText: ''
    }
  },

  methods: {
    ...mapActions(['connectMarketplace']),

    onClosed () {
      this.isFormSended = false
    },

    async onSubmit (data) {
      try {
        this.isLoading = true

        const response = await this.connectMarketplace(data)

        const { name } = data.params

        if (typeof response === 'string') {
          window.location = response
          localStorage.setItem('seller_ali_shop_name', name)
          await this.$user.getProfile()
        }
      } catch (e) {
        this.isFormSended = false
      } finally {
        this.isLoading = false
      }
    },

    onSuccessClick () {
      this.$modal.hide(this.modalName)
      this.$modal.show('ali-auth-modal')
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-container
    max-width 390px
    margin 0 auto
    padding 20px 15px 60px
    box-sizing border-box

    @media (min-width 768px)
      padding 20px 0 60px
</style>

<template>
  <modal
    :name="modalName"
    height="auto"
    width="540px"
    @before-close="$emit('close')"
  >
    <div class="warn-modal">
      <close-button @click="$modal.hide(modalName)" />

      <slot name="icon" />

      <heading level="3">
        <slot name="title" />
      </heading>

      <span class="warn-modal__text">
        <slot />
      </span>

      <x-btn
        block
        class="warn-modal__btn"
        @click="$emit('click')"
      >
        {{ action }}
      </x-btn>
    </div>
  </modal>
</template>

<script>
import CloseButton from '@/components/Interface/CloseButton.vue'
import Heading from '@/components/Common/Heading.vue'

export default {
  components: {
    CloseButton,
    Heading
  },

  props: {
    action: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      modalName: 'warn-modal'
    }
  }
}
</script>

<style lang="stylus" scoped>
  .warn-modal
    padding 30px
    text-align center

    &__text
      display block
      max-width 481px
      color #7b7b85

    &__btn
      margin-top 46px
</style>

<template>
  <div class="filters content-columns content-columns_content_promotion-filters">
    <div class="column column_layout_a">
      <ul class="filters__list">
        <li class="filters__item">
          <shop-filter
            :shop="shop"
            :shops="accessedShops"
            :params="{ marketplace_code: 'ali' }"
            @change:shop="option => $emit('change:shop', option)"
          />
        </li>

        <li class="filters__item filters__item_content_period">
          <x-form-item
            name="Период регистрации"
            label="Период регистрации"
            label-size="s"
          >
            <datepicker
              border
              has-reset-button
              :default-period="currentSignPeriod"
              :value="signPeriod"
              @change="value => $emit('change:sign-period', value)"
              @change-default-period="p => currentSignPeriod = p"
            />
          </x-form-item>
        </li>

        <li class="filters__item filters__item_content_period">
          <x-form-item
            name="Период акции"
            label="Период акции"
            label-size="s"
          >
            <datepicker
              border
              has-reset-button
              :default-period="currentPromotionPeriod"
              :value="promotionPeriod"
              @change="value => $emit('change:promotion-period', value)"
              @change-default-period="p => currentPromotionPeriod = p"
            />
          </x-form-item>
        </li>

        <li class="filters__item filters__item_content_statuses">
          <x-form-item
            name="Статус участия"
            label="Статус участия"
            label-size="s"
          >
            <x-select
              track-by="id"
              label="label"
              :value="shopStatus"
              :options="shopStatuses"
              :limit="1"
              :searchable="false"
              :allow-empty="false"
              placeholder="Все статусы"
              @select="option => $emit('change:shop-status', option)"
            />
          </x-form-item>
        </li>
      </ul>
    </div>

    <div class="column column_layout_b">
      <reset-button
        block
        @click="clearFilters"
      >
        Очистить фильтры
      </reset-button>
    </div>
  </div>
</template>

<script>
import { defaultFilterOption } from '@/constants/dashboards-filters'
import CalendarPeriods from '@/constants/calendar-periods'
import ShopFilter from '@/components/Interface/ShopFilter.vue'
import Datepicker from '@/components/Interface/Datepicker.vue'
import ResetButton from '@/components/Interface/ResetButton.vue'
import { createNamespacedHelpers } from 'vuex'

const today = new Date()

const { mapState } = createNamespacedHelpers('marketplaces')
const { mapGetters } = createNamespacedHelpers('profile')

export default {
  components: {
    ShopFilter,
    Datepicker,
    ResetButton
  },

  props: {
    shop: {
      type: Object,
      default: () => defaultFilterOption
    },
    signPeriod: {
      type: Object,
      default: () => ({
        start: today,
        end: today
      })
    },
    promotionPeriod: {
      type: Object,
      default: () => ({
        start: today,
        end: today
      })
    },
    shopStatus: {
      type: [String, Object],
      default: ''
    }
  },

  data () {
    return {
      shopStatuses: [
        { label: 'Все статусы', id: 'ALL' },
        { label: 'Не зарегистрирован', id: 'shop_is_not_registered' },
        { label: 'В процессе', id: 'shop_is_registering' },
        { label: 'Зарегистрирован', id: 'shop_is_registered' }
      ],
      currentSignPeriod: CalendarPeriods.TODAY,
      currentPromotionPeriod: CalendarPeriods.TODAY
    }
  },

  computed: {
    ...mapState(['shops']),
    ...mapGetters(['isUserAccessedToSection']),

    accessedShops () {
      return this.shops.filter(shop => {
        return this.isUserAccessedToSection({
          shopId: shop.id,
          section: 'access_promotion'
        })
      })
    }
  },

  methods: {
    clearFilters () {
      this.$emit('clear')
    }
  }
}
</script>

<style lang="stylus" scoped>
  .filters
    &__list
      display flex
      margin 0
      padding 0
      list-style none
      margin-left -10px

    &__item
      flex-basis 160px
      margin-left 10px

      &_content_period,
      &_content_statuses
        flex-basis 200px

  .content-columns_content_promotion-filters
    display flex
    justify-content space-between
    align-items flex-end

    .column
      &_layout_a
        flex-grow 1

      &_layout_b
        flex-basis 186px
        margin-left 20px
</style>

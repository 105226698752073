<template>
  <div class="grid__notifications">
    <notification
      v-if="uploaded"
      type="success"
      icon="success"
    >
      Товары загружены успешно!
    </notification>

    <notification
      v-if="submitted"
      type="info"
      icon="info"
    >
      Идет регистрация товаров в акцию.
    </notification>

    <notification
      v-else-if="signedUp"
      type="success"
      icon="success"
    >
      Товары успешно зарегистрированы в акцию. До старта акции осталось:
      <strong class="promo-start-diff">
        {{ promoStartDiff }}
      </strong>
    </notification>

    <notification
      v-if="errors.length"
      type="error"
      class="grid-errors"
    >
      <svg-icon
        name="error-triangle"
        class="grid-errors__icon"
      />
      <div class="grid-errors__heading">
        <div>
          При загрузке найдено <strong>{{ errors.length }} {{ errorsCountText }}</strong>.
        </div>

        <x-btn
          ghost
          @click="$emit('error-click')"
        >
          Подробнее
        </x-btn>
      </div>
    </notification>
  </div>
</template>

<script>
import Notification from '@/components/Interface/Notification.vue'
import { getDiff } from '@/utils/date-formatter'
import createCountFormatter from '@/utils/count-formatter'

export default {
  components: {
    Notification
  },

  props: {
    errors: {
      type: Array,
      default: () => []
    },
    uploaded: Boolean,
    submitted: Boolean,
    signedUp: Boolean,
    promotion: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    promoStartDiff () {
      const diff = getDiff(new Date(), this.promotion.promo_start_date)
      return `${diff.days()} дн ${diff.hours()} ч ${diff.minutes()} мин`
    },

    errorsCountText () {
      const formatErrorsCount = createCountFormatter({
        few: 'ошибок',
        one: 'ошибка',
        two: 'ошибки'
      })
      return formatErrorsCount(this.errors.length)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .grid-errors
    margin-bottom 6px
    padding-left 32px
    font-size 12px

    &__heading
      display flex
      align-items center
      font-size: $font-sizes.caption
      font-weight: $font-weights.bold

    &__icon
      margin-right 5px
      width 13px
      height 13px

  .promo-start-diff
    margin-left 5px
</style>

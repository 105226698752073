const monoFontsStyle = {
  display: 'block',
  fontFamily: 'Arial',
  fontSize: '13px',
  textAlign: 'right'
}

export default [{
  name: 'Фото',
  key: 'image',
  type: 'image'
}, {
  name: 'Ali ID',
  sortable: true,
  key: 'ali_id',
  unfoldable: true
}, {
  name: 'Название',
  sortable: true,
  key: 'name'
}, {
  name: 'Скидка на сайте',
  sortable: true,
  key: 'discount',
  type: 'editable',
  styles: { ...monoFontsStyle, minWidth: '120px' },
  validationRules: 'required|integer|greaterThanZero|between:0,99',
  styleType: 'numbers'
}, {
  name: 'Скидка в мобильном приложении',
  sortable: true,
  key: 'discount_mob',
  type: 'editable',
  styles: { ...monoFontsStyle, minWidth: '140px' },
  validationRules: 'required|integer',
  styleType: 'numbers'
}]

export const offerColumns = [{
  name: 'Артикул/штрих-код',
  sortable: false,
  key: 'external_id'
}, {
  name: 'Базовая цена',
  sortable: false,
  key: 'price_recommended',
  type: 'price',
  styleType: 'numbers'
}, {
  name: 'Сток на маркетплейсе',
  sortable: false,
  key: 'quantity_in_promo',
  styles: { ...monoFontsStyle, minWidth: '90px' },
  styleType: 'numbers'
}, {
  name: 'Акционный сток',
  sortable: false,
  key: 'quantity',
  type: 'editable',
  styles: { ...monoFontsStyle, minWidth: '120px' },
  validationRules: 'required|integer|greaterThanZero',
  styleType: 'numbers'
}, {
  name: 'Акционная цена',
  sortable: false,
  type: 'price',
  key: 'price_promo',
  styleType: 'numbers'
}, {
  name: 'Акционная цена (моб)',
  sortable: false,
  type: 'price',
  key: 'price_promo_mob',
  styleType: 'numbers'
}]

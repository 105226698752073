<template>
  <x-card
    class="signup-tabs"
    size="xl"
  >
    <div class="signup-tabs__heading">
      <tabs
        type="tab"
        :tabs="tabs"
        :active="currentTab.path"
        class="signup-tabs__tabs"
      />

      <div class="signup-tabs__buttons">
        <signup-buttons
          v-if="!hideButtons"
          :active-tab="activeTab"
          :counter="currentTab.counter"
          :has-checked-products="!!checkedOffersIds.length"
          :is-deleting="isDeleting"
          :is-products-updating="isProductsUpdating"
          :uploaded="uploaded"
          @download="onDownloadTemplate"
          @upload="uploadProducts"
          @delete="onDeleteProducts"
          @delete-all="onDeleteProducts([], { deleteAll: true })"
        />

        <x-popover
          v-if="!isEditable && productUpdateAccessed"
          trigger="hover"
        >
          <x-btn
            outline
            :disabled="isUpdateDisabled"
            :loading="isProductsUpdating"
            class="signup-tabs__update"
            @click="updateProducts"
          >
            Обновить доступные товары
          </x-btn>

          <template
            v-if="isUpdateDisabled"
            #popover
          >
            <div class="signup-tabs__update-tooltip">
              {{ tooltipText }}
            </div>
          </template>
        </x-popover>
      </div>
    </div>

    <transition-group name="fade-in">
      <template v-if="currentTab.counter">
        <signup-products
          key="products"
          :promotion="currentPromotion"
          :editable="isEditable"
          :cells="computedCells"
          :columns="computedColumns"
          :uploaded="uploaded"
          :is-loading="isLoading || isDeleting"
          :checked-products-ids="checkedOffersIds"
          @cell-checked="checkProduct"
          @check-all-row-offers="checkAllRowOffers"
          @delete-product="onDeleteProducts"
          @change:only-errors-filter="onOnlyErrorsFilterChange"
        />

        <pagination
          v-if="!isLoading && computedCount"
          key="pagination"
          :data="pagination"
          :current-page="currentPage"
          class="signup-tabs__pagination"
          @change="onPaginationChange"
        />
      </template>

      <x-placeholder
        v-else-if="isEditable && !isShowErrors && !hideButtons"
        key="placeholder-1"
        icon="table-empty"
        desc="В выгруженном файле заполните колонки “Скидка на сайте”, “Скидка в мобильном приложении” и “Акционный сток”"
      >
        <template #bottom>
          <file-upload
            accept=".xls, .xlsx"
            :uploaded="uploaded"
            @upload="uploadProducts"
          >
            <x-btn
              class="signup-tabs__upload-btn"
            >
              Загрузить файл с товарами
            </x-btn>
          </file-upload>
        </template>
      </x-placeholder>

      <x-placeholder
        v-else-if="noDataStatus"
        key="placeholder-2"
        :desc="noDataStatusText"
      />

      <signup-products
        v-else
        key="products2"
        :editable="isEditable"
        :cells="computedCells"
        :columns="computedColumns"
        :promotion="currentPromotion"
      />
    </transition-group>

    <download-template-modal
      @confirm="changeTab('signup')"
    />
  </x-card>
</template>

<script>
import Tabs from '@/components/Interface/Tabs.vue'
import SignupProducts from '@/components/Promotion/SignupProducts.vue'
import Pagination from '@/components/Interface/Pagination.vue'
import SignupButtons from '@/components/Promotion/SignupButtons.vue'
import FileUpload from '@/components/Interface/FileUpload.vue'
import DownloadTemplateModal from './Modals/DownloadTemplateModal.vue'
import columns from '@/constants/sign-product'
import columnsEditable from '@/constants/sign-products-editable'
import { createNamespacedHelpers } from 'vuex'
import {
  readOnlyStatuses,
  noDataStatuses,
  productUpdateAccessedStatuses
} from '@/constants/promotion-statuses'
import { getDiff } from '@/utils/date-formatter'
import { authStatuses } from '@/constants/shops'

const { mapActions, mapState } = createNamespacedHelpers('promotions')

export default {
  components: {
    Tabs,
    SignupProducts,
    Pagination,
    SignupButtons,
    DownloadTemplateModal,
    FileUpload
  },

  props: {
    availableProducts: {
      type: Array,
      default: () => []
    },
    availableProductsCount: {
      type: Number,
      default: 0
    },
    products: {
      type: Array,
      default: () => []
    },
    productsCount: {
      type: Number,
      default: 0
    },
    productsWithErrors: {
      type: Array,
      default: () => []
    },
    productsWithErrorsCount: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      currentPage: 1,
      uploaded: false,
      checkedOffersIds: [],
      isShowErrors: false,
      isDeleting: false,
      isProductsUpdating: false,
      showPointerIcon: false
    }
  },

  computed: {
    ...mapState(['promotion', 'errors']),

    id () {
      return this.$route.params.id
    },

    activeTab () {
      return this.$route.query?.tab || 'list'
    },

    currentTab () {
      return this.tabs.find(tab => tab.path === this.activeTab)
    },

    tabs () {
      return [{
        title: 'Доступные для регистрации товары',
        path: 'list',
        counter: this.availableProductsCount
      }, {
        title: 'Регистрация товара',
        path: 'signup',
        counter: this.productsCount,
        icon: this.showPointerIcon ? 'arrow-pointer' : null,
        iconClassList: ['tabs__icon_viewtype_end', 'tabs__icon_content_pointer']
      }]
    },

    isEditable () {
      return this.activeTab === 'signup'
    },

    computedCells () {
      return this.isEditable
        ? this.isShowErrors
          ? this.productsWithErrors
          : this.products
        : this.availableProducts
    },

    computedCount () {
      return this.isEditable
        ? this.isShowErrors
          ? this.productsWithErrorsCount
          : this.productsCount
        : this.availableProductsCount
    },

    computedColumns () {
      return this.isEditable ? columnsEditable : columns
    },

    currentPromotion () {
      return this.promotion.id ? this.promotion : this.$route.params.promotion
    },

    hideButtons () {
      if (this.currentPromotion.is_demo) return
      return readOnlyStatuses.includes(this.currentPromotion.shop_status_detail)
    },

    productUpdateAccessed () {
      return productUpdateAccessedStatuses.includes(this.promotion.shop_status_detail)
    },

    isShopAuthorized () {
      return this.promotion.shop_authorization_status === authStatuses.success
    },

    isUpdateDisabled () {
      const { last_available_product_update: lastUpdate } = this.promotion
      if (!lastUpdate && this.isShopAuthorized) return
      const diff = getDiff(lastUpdate, new Date())
      return diff.minutes() < 30 || !this.isShopAuthorized
    },

    tooltipText () {
      return this.isShopAuthorized ? 'Обновлять можно не более чем раз в 30 минут' : 'Ошибка авторизации в сервисе Промо'
    },

    noDataStatus () {
      return noDataStatuses.find(item => this.currentPromotion.shop_status_detail === item.status)
    },

    noDataCbText () {
      const start = this.currentPromotion.registration_start_date
      const end = this.currentPromotion.registration_end_date
      return this.noDataStatus.cbText(start, end)
    },

    noDataStatusText () {
      if (this.noDataStatus.text && !this.isEditable) {
        return this.noDataStatus.text
      }
      if (this.noDataStatus.editableText && this.isEditable) {
        return this.noDataStatus.editableText
      }
      if (this.noDataStatus.cbText && this.isEditable) {
        return this.noDataCbText
      }
      return ''
    },

    pagination () {
      return {
        count: this.computedCount,
        limit: null
      }
    }
  },

  created () {
    this.checkNeededToShowPointerIcon()
  },

  methods: {
    ...mapActions([
      'getPromotion',
      'downloadProductsTemplate',
      'deleteProducts',
      'uploadProductsTemplate',
      'getPromotionProducts',
      'updateAvailableProducts'
    ]),

    checkNeededToShowPointerIcon () {
      const flag = JSON.parse(localStorage.getItem(`seller_show_pointer_${this.id}`))
      this.showPointerIcon = Boolean(flag ?? 0)
    },

    async updateProducts () {
      try {
        this.isProductsUpdating = true
        await this.updateAvailableProducts({
          id: this.currentPromotion.id
        })
      } finally {
        this.isProductsUpdating = false
      }
    },

    async onDeleteProducts (product, params) {
      try {
        this.isDeleting = true

        const deleted = await this.deleteProducts({
          product_ids: product,
          offer_ids: this.checkedOffersIds,
          promoId: this.currentPromotion.id,
          ...params
        })

        if (deleted) {
          await this.getPromotionProducts({
            id: this.currentPromotion.id,
            params: { only_errors: this.isShowErrors }
          })
        }
      } finally {
        this.isDeleting = false
        this.checkedOffersIds = []
      }
    },

    async uploadProducts (file) {
      const { id } = this.currentPromotion

      const success = await this.uploadProductsTemplate({ id, file })
      await this.getPromotionProducts({ id })
      await this.getPromotion(id)

      if (success) {
        this.uploaded = true
        this.checkedOffersIds = []

        setTimeout(() => (this.uploaded = false), 3000)
      }
    },

    changeTab (tab) {
      this.$router.replace({
        query: { ...this.$route.query, tab }
      })
    },

    checkProduct (cell) {
      const checkedId = this.checkedOffersIds.findIndex(id => cell.id === id)

      if (~checkedId) {
        this.checkedOffersIds.splice(checkedId, 1)
      } else {
        this.checkedOffersIds.push(cell.id)
      }
    },

    checkAllRowOffers (checked, offers) {
      this.checkedOffersIds = this.checkedOffersIds.filter(id => {
        return !offers.some(o => o.id === id)
      })

      if (checked) {
        this.checkedOffersIds.push(...offers.map(o => o.id))
      }
    },

    onPaginationChange ({ page, limit, offset }) {
      this.currentPage = page

      this.pagination.limit = limit

      this.$emit('change:pagination', {
        page,
        limit,
        offset,
        tabName: this.currentTab.path
      })
    },

    onOnlyErrorsFilterChange (onlyErrors) {
      this.isShowErrors = onlyErrors
      this.$emit('change:only-errors-filter', onlyErrors)
    },

    onDownloadTemplate () {
      this.downloadProductsTemplate(this.currentPromotion.id)
      this.showPointerIcon = true
      localStorage.setItem(`seller_show_pointer_${this.id}`, 1)
      this.$modal.show('download-template-modal')
    }
  }
}
</script>

<style lang="stylus" scoped>
  .signup-tabs
    &__heading
      display flex
      justify-content space-between
      padding-bottom 15px

    &__buttons
      display flex

    &__update
      margin-left 10px

    &__update-tooltip
      padding 10px

    &__loader
      padding 40px
      text-align center

    &__tabs
      border-bottom none

      &::before
        display none

    &__pagination
      margin-top 44px

    &__upload-btn
      margin 0 auto

  .fade-in-enter-active
    transition opacity .2s ease-in

  .fade-in-enter,
  .fade-in-leave-to
    opacity 0
</style>

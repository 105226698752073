<template>
  <modal
    :name="modalName"
    :max-width="540"
    :min-width="280"
    width="90%"
    height="auto"
    :adaptive="true"
  >
    <div class="modal-container">
      <svg-icon
        name="download--cloud"
        class="modal-container__icon"
      />
      <p class="modal-container__text">
        Шаблон с вашими товарами формируется. Скачивание начнется через несколько секунд. Пожалуйста, подождите.
      </p>

      <heading
        tag="h3"
        class="modal-container__heading"
      >
        Для регистрации товаров
      </heading>

      <div class="modal-container__text">
        1. Откройте скачанный шаблон.<br>
        2. Заполните столбцы "Акционный сток", "Скидка на сайте", "Скидка в мобильном приложении" для каждого товара.<br>
        3. Сохраните файл и загрузите его на вкладке "Регистрация товаров".
      </div>

      <ul class="modal-container__actions">
        <li class="modal-container__action">
          <x-btn
            block
            color="gray"
            @click="$modal.hide(modalName)"
          >
            Закрыть
          </x-btn>
        </li>

        <li class="modal-container__action">
          <x-btn
            block
            @click="onConfirmClick"
          >
            Перейти к регистрации товаров
          </x-btn>
        </li>
      </ul>

      <close-button
        @click="$modal.hide(modalName)"
      />
    </div>
  </modal>
</template>

<script>
import Heading from '@/components/Common/Heading.vue'
import CloseButton from '@/components/Interface/CloseButton.vue'

export default {
  components: {
    Heading,
    CloseButton
  },

  data () {
    return {
      modalName: 'download-template-modal'
    }
  },

  methods: {
    onConfirmClick () {
      this.$modal.hide(this.modalName)
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-container
    padding 50px 30px 40px

    &__icon
      display block
      width 110px
      height 73px
      margin 0 auto 20px

    &__text
      font-size 14px
      color #3c3c47

    &__heading
      margin-top 40px
      margin-bottom 9px
      font-size 20px
      color #3c3c47

    &__actions
      display flex
      margin 30px 0 0 -10px
      padding 0
      list-style none

    &__action
      flex-basis 50%
      margin-left 10px
</style>

<template>
  <modal
    height="auto"
    :name="modalName"
    width="50%"
  >
    <div class="modal-body">
      <close-button
        @click="$modal.hide(modalName)"
      />

      <heading level="4">
        <slot name="title" />
      </heading>

      <notification
        type="error"
        max-height="400"
      >
        <ol class="modal-body__error-list">
          <li
            v-for="(error, idx) in errors"
            :key="error"
            class="modal-body__error"
          >
            <template v-if="enumerable">
              {{ idx + 1 }}.
            </template>
            {{ error }}
          </li>
        </ol>
      </notification>
    </div>
  </modal>
</template>

<script>
import CloseButton from '@/components/Interface/CloseButton.vue'
import Heading from '@/components/Common/Heading.vue'
import Notification from '@/components/Interface/Notification.vue'

export default {
  components: {
    CloseButton,
    Heading,
    Notification
  },

  props: {
    errors: {
      type: Array,
      required: true
    },
    enumerable: Boolean
  },

  data () {
    return {
      modalName: 'errors-modal'
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-body
    padding 30px
</style>

<template>
  <x-card
    class="promotion-card content-columns content-columns_content_promotion-card"
    size="xl"
  >
    <div class="column column_layout_a">
      <header class="promotion-card__header">
        <div class="promotion-card__thumbnail">
          <svg-icon
            :name="promotion.marketplace_code"
            class="promotion-card__aliexpress"
          />
        </div>
        <div class="promotion-card__header-desc">
          <promotion-card-status :status="promotion.promo_status" />
          <router-link
            :to="{ name: 'promotion.signup', params: { promotion, id: promotion.id }}"
            class="promotion-card__title-text"
          >
            {{ promotion.name }}
          </router-link>
        </div>
      </header>

      <p class="promotion-card__text">
        {{ promotion.description }}
      </p>

      <x-btn
        ghost
        @click="openPromoDetailsModal"
      >
        Подробности акции
      </x-btn>
    </div>

    <div class="column column_layout_b">
      <div class="promotion-card__action">
        <promotion-card-action
          :is-promo-signup="isPromoSignup"
          :action="promoActions[promotion.shop_status_detail]"
          :promotion="promotion"
        />
      </div>

      <ul class="promotion-card__properties promotion-card__properties_content_periods">
        <li class="promotion-card__property">
          <dl class="promotion-card__definition">
            <dt class="promotion-card__definition-title">
              Старт регистрации
            </dt>
            <dd class="promotion-card__definition-content">
              {{ formatDate(promotion.registration_start_date) }}
            </dd>
          </dl>
        </li>

        <li class="promotion-card__property">
          <dl class="promotion-card__definition">
            <dt class="promotion-card__definition-title">
              Старт акции
            </dt>
            <dd class="promotion-card__definition-content">
              {{ formatDate(promotion.promo_start_date) }}
            </dd>
          </dl>
        </li>

        <li class="promotion-card__property">
          <dl class="promotion-card__definition">
            <dt class="promotion-card__definition-title">
              Конец регистрации
            </dt>
            <dd class="promotion-card__definition-content">
              {{ formatDate(promotion.registration_end_date) }}
            </dd>
          </dl>
        </li>

        <li class="promotion-card__property">
          <dl class="promotion-card__definition">
            <dt class="promotion-card__definition-title">
              Конец акции
            </dt>
            <dd class="promotion-card__definition-content">
              {{ formatDate(promotion.promo_end_date) }}
            </dd>
          </dl>
        </li>
      </ul>
    </div>

    <div class="column column_layout_c">
      <div class="promotion-card__shop">
        <div class="promotion-card__shop-subtitle">
          магазин
        </div>
        <h4 class="promotion-card__shop-title">
          {{ promotion.shop_name }}
        </h4>
      </div>

      <ul class="promotion-card__properties">
        <li class="promotion-card__property">
          <dl class="promotion-card__definition">
            <dt class="promotion-card__definition-title">
              Доступно к регистрации
            </dt>
            <dd class="promotion-card__definition-content">
              {{ promotion.product_available_count }}
            </dd>
          </dl>
        </li>

        <li class="promotion-card__property">
          <dl class="promotion-card__definition">
            <dt class="promotion-card__definition-title">
              {{ productRegistrationStatus }}
            </dt>
            <dd class="promotion-card__definition-content">
              {{ promotion.product_registered_count }}
            </dd>
          </dl>
        </li>
      </ul>
    </div>
  </x-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import formatDate from '@/utils/date-formatter'
import PromotionCardStatus from '@/components/Promotion/PromotionCardStatus.vue'
import PromotionCardAction from '@/components/Promotion/PromotionCardAction.vue'
import promoActions from '@/constants/promo-actions.js'
import { registeredStatuses } from '@/constants/promotion-statuses.js'

const { mapActions: mapTrackingActions } = createNamespacedHelpers('tracking')

export default {
  components: {
    PromotionCardStatus,
    PromotionCardAction
  },

  props: {
    promotion: {
      type: Object,
      required: true
    },
    isPromoSignup: Boolean
  },

  computed: {
    promoActions () {
      const promoRegistered = this.promotion.is_promo_registered
      return {
        ...promoActions,
        shop_registration_ready: promoRegistered ? 'continue_sign' : 'sign'
      }
    },

    productRegistrationStatus () {
      const productsAreRegistered = registeredStatuses.includes(this.promotion.shop_status_detail)
      return productsAreRegistered ? 'Товаров зарегистрировано' : 'Товаров к регистрации'
    }
  },

  methods: {
    ...mapTrackingActions(['setEvent']),
    formatDate,

    openPromoDetailsModal () {
      this.$modal.show('promo-details', { id: this.promotion.id })
      this.setEvent({
        name: 'mmp_promotion_details',
        product: 'MMP',
        screen: 'mmp_promotion',
        promo_id: this.promotion.id,
        promo_category: null
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .promotion-card
    &__header
      display flex
      align-items center
      margin-bottom 22px

    &__thumbnail
      flex-shrink 0
      width 36px
      height 36px
      margin-right 10px

    &__aliexpress
      width 36px
      height 36px

    &__title-text
      display block
      margin-top 2px
      font-size 18px
      font-weight normal
      color #3c3d47
      text-decoration none
      transition color .3s

      &:hover
        color #4430bf

    &__text
      margin-bottom 6px
      font-size 14px
      color #7b7b85

    &__shop
      margin-bottom 22px

    &__shop-subtitle
      font-size 12px
      color #a3a3ac

    &__shop-title
      font-size 18px

    &__action
      margin-bottom 22px

    &__properties
      margin 0
      margin-top -8px
      padding 0
      list-style none

      &_content_periods
        display flex
        flex-wrap wrap
        justify-content space-between

        .promotion-card__property
          flex-basis 48%

    &__property
      margin-bottom 8px

    &__definition-title
      margin-bottom 4px
      color #7b7b85

    &__definition-content
      color #3c3d47

  .content-columns_content_promotion-card
    display flex

    .column
      &_layout_a
        flex-basis 44%
        margin-right 30px

      &_layout_b
        flex-basis 30%
        padding-right 40px
        border-right 1px solid #e5e5e9

      &_layout_c
        flex-basis 20%
        margin-left 40px
</style>

import formatDate from '@/utils/date-formatter'

export default Object.freeze({
  promo_registration_opened: 'Регистрация открыта',
  promo_registration_waited: 'Ждет старта регистрации',
  promo_registration: 'Регистрация идет',
  promo_started: 'Проведение акции',
  promo_finished: 'Акция завершена',
  promo_start_waited: 'Ждет старта акции'
})

export const readOnlyStatuses = Object.freeze([
  'shop_registration_not_started',
  'shop_registration_in_progress',
  'shop_registration_not_available',
  'shop_promo_is_participating',
  'shop_promo_skip',
  'shop_promo_done',
  'shop_promo_skipped'
])

export const registeredStatuses = [
  'shop_registration_in_progress',
  'shop_registration_done',
  'shop_promo_is_participating',
  'shop_promo_skip',
  'shop_promo_done'
]

export const productUpdateAccessedStatuses = Object.freeze([
  'shop_registration_not_available',
  'shop_registration_ready',
  'shop_registration_started',
  'shop_registration_in_progress',
  'shop_registration_failed',
  'shop_registration_done'
])

export const noDataStatuses = Object.freeze([{
  status: 'shop_registration_not_started',
  editableText: 'Регистрация товаров еще недоступна'
}, {
  status: 'shop_registration_not_available',
  text: 'Нет доступных товаров',
  editableText: 'Нет доступных товаров для участия в акции'
}, {
  status: 'shop_promo_skipped',
  editableText: 'Акция уже завершена, возможность зарегистрировать товары в завершенную акцию отсутствует.'
}, {
  status: 'shop_promo_skip',
  text: 'Нет доступных товаров',
  cbText: (start, end) => `Регистрация в акцию была доступна в период с ${formatDate(start)} до ${formatDate(end)}. В данный момент регистрация товаров в акцию недоступна.`
}])

export const shopAuthStatuses = Object.freeze({
  'not_authorized': {
    icon: 'error-triangle',
    title: 'Вы не авторизованы',
    text: 'Для работы с акциями введите логин и пароль от магазина на AliExpress на нашей странице авторизации и повторите попытку',
    action: 'Авторизоваться'
  },
  'wrong_password': {
    icon: 'error-triangle',
    title: 'Логин или пароль неверные',
    text: 'Не удается авторизоваться на AliExpress, исправьте введенные ранее логин или пароль на нашей странице авторизации и повторите попытку',
    action: 'Исправить'
  }
})

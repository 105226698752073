<template>
  <modal
    :name="modalName"
    height="auto"
    width="820px"
    :scrollable="true"
    @before-open="onBeforeOpen"
  >
    <div class="promo-details">
      <close-button
        @click="$modal.hide(modalName)"
      />
      <h2 class="promo-details__title">
        Подробности акции
      </h2>

      <div
        v-if="isLoading"
        class="promo-details__loader"
      >
        <loader />
      </div>

      <template v-else>
        <div
          v-for="(section, idx) in sections"
          :key="`${section.title}-${idx}`"
          class="promo-details__section"
        >
          <h3 class="promo-details__section-title">
            {{ section.title }}
          </h3>

          <div class="promo-details__section-body">
            <div class="promo-details__section-heading">
              <div
                v-for="(col, id) in section.columns"
                :key="`${col}-${id}`"
                class="promo-details__section-column"
              >
                <strong class="promo-details__section_key">
                  {{ col }}
                </strong>
              </div>
            </div>

            <div
              v-for="(cell, id) in section.cells"
              :key="id"
              class="promo-details__section-cell"
            >
              <span
                v-for="(item, i) in cell"
                :key="`${item}-${i}`"
                class="promo-details__section_value"
              >
                {{ item }}
              </span>
            </div>
          </div>
        </div>

        <div
          v-if="!sections.length"
          class="promo-details__placeholder"
        >
          Детали данной акции отсутствуют
        </div>

        <x-btn
          v-if="sections.length"
          block
          color="gray"
          class="promo-details__hide"
          @click="$modal.hide(modalName)"
        >
          Закрыть
        </x-btn>
      </template>
    </div>
  </modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import CloseButton from '@/components/Interface/CloseButton.vue'
import Loader from '@/components/Common/Loader.vue'

const { mapActions, mapState } = createNamespacedHelpers('promotions')

export default {
  components: {
    CloseButton,
    Loader
  },

  data () {
    return {
      modalName: 'promo-details',
      isLoading: true
    }
  },

  computed: {
    ...mapState(['details']),

    sections () {
      if (!this.details) return []

      const properties = Object
        .keys(this.details)
        .filter(k => typeof this.details[k] === 'object')
        .map(el => this.details[el])

      if (properties.every(p => !Object.keys(p).length)) return []

      /* eslint-disable-next-line */
      const { promo_requirements, shop_requirements, item_requirements } = this.details

      return [{
        title: 'Основные требования',
        columns: ['Требования к акции', 'Требования'],
        cells: Object.entries(promo_requirements)
      }, {
        title: 'Требования к магазинам',
        columns: ['Соответствует', 'Требование'],
        cells: Object.entries(shop_requirements)
      }, {
        title: 'Требования к товарам',
        columns: ['Требование', 'Требования к товарам'],
        cells: Object.entries(item_requirements)
      }]
    }
  },

  methods: {
    ...mapActions(['getPromotionDetails']),

    async onBeforeOpen ({ params }) {
      const { id } = params

      if (id) {
        this.isLoading = true
        await this.getPromotionDetails(id)
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .promo-details
    padding 30px
    box-sizing border-box
    color #474852

    &__title
      margin-bottom 20px
      font-weight $font-weights.bold

    &__loader
      padding 40px
      text-align center

    &__section
      &_key
        padding 8px
        font-size 14px

      &_value
        flex 33.3%
        padding 8px
        font-size 14px

        &:first-child
          color rgba(#2c2c37, 0.5)

        &:last-child
          flex 70%

    &__section-title
      padding 10px 0

    &__section-body
      display flex
      flex-direction column

    &__section-heading
      background-color rgba(#cacad3, 0.3)
      display flex
      border-bottom 1px solid rgba(#cacad3, 0.3)

    &__section-column
      display flex
      flex-direction column
      flex 33.3%

      &:last-child
        flex 70%

    &__section-cell
      display flex

      &:not(:last-child)
        border-bottom 1px solid rgba(#cacad3, 0.3)

    &__hide
      margin-top 80px
</style>

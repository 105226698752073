<template>
  <div
    v-if="status"
    class="promotion-card-status"
    :class="classes"
  >
    {{ title }}
  </div>
</template>

<script>
import statuses from '@/constants/promotion-statuses'

export default {
  props: {
    status: {
      type: String,
      default: ''
    }
  },

  computed: {
    classes () {
      return `promotion-card-status_viewtype_${this.status.toLowerCase()}`
    },

    title () {
      return statuses[this.status]
    }
  }
}
</script>

<style lang="stylus" scoped>
  .promotion-card-status
    display inline-block
    padding 0 6px
    font-size 12px
    line-height 16px
    color #3c3c47
    border-radius 9px

    &_viewtype_promo_registration_opened
      background-color #ceefce

    &_viewtype_promo_registration_waited,
    &_viewtype_promo_start_waited
      background-color #fceec6

    &_viewtype_promo_registration
      background-color #f6f6ff

    &_viewtype_promo_started
      background-color #ecdeff

    &_viewtype_promo_finished
      background-color #e5e5e9
</style>

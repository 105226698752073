<template>
  <section class="promotion-info">
    <x-btn
      ghost
      @click="toggle"
    >
      <header class="promotion-info__heading">
        <h2>Как зарегистрировать товар в акцию?</h2>

        <svg-icon
          name="info-fill"
          class="promotion-info__icon"
        />

        <div class="promotion-info__unfold">
          <span
            v-if="shouldShow"
            class="promotion-info__opener-expand"
          >
            Свернуть
          </span>
          <span
            v-else
            class="promotion-info__opener-narrow"
          >
            Развернуть
          </span>
          <svg-icon
            name="chevron-down"
            class="promotion-info__unfold-icon"
            :class="{'promotion-info__unfold-icon_down': !shouldShow}"
          />
        </div>
      </header>
    </x-btn>

    <transition-expand>
      <ol
        v-if="shouldShow"
        class="promotion-info__list"
      >
        <li
          v-for="(block, idx) in blocks"
          :key="block.title + idx"
          class="promotion-info__block"
        >
          <instruction-block
            :block="block"
          />
        </li>
      </ol>
    </transition-expand>
  </section>
</template>

<script>
import TransitionExpand from '@/components/Interface/TransitionExpand.vue'
import InstructionBlock from '@/components/Promotion/InstructionBlock.vue'

export default {
  components: {
    TransitionExpand,
    InstructionBlock
  },

  data () {
    return {
      shouldShow: true,
      blocks: [
        {
          title: '1. Выгрузите шаблон',
          description: 'Выгрузите шаблон списка товара из вкладки “Доступный для регистрации товар”',
          image: 'template'
        },
        {
          title: '2. Заполните таблицу',
          description: 'В выгруженном файле заполните колонки “Скидка на сайте”, “Скидка в мобильном приложении” и “Акционный сток”',
          image: 'table'
        },
        {
          title: '3. Загрузите товары',
          description: 'Загрузите заполненный шаблон на вкладку “Регистрация товара',
          image: 'products'
        }
      ]
    }
  },

  created () {
    const flag = JSON.parse(localStorage.getItem('seller_show_instruction'))
    this.shouldShow = Boolean(flag ?? 1)
  },

  methods: {
    toggle () {
      localStorage.setItem('seller_show_instruction', this.shouldShow ? 0 : 1)
      this.shouldShow = !this.shouldShow
    }
  }
}
</script>

<style lang="stylus" scoped>
  .promotion-info
    position relative

    &__heading
      display flex
      align-items center
      margin-bottom 0
      font-weight $font-weights.bold
      color #3c3c47

      &:hover
        .promotion-info__opener-expand,
        .promotion-info__opener-narrow
          font-size 14px
          transform translateX(0)
          opacity 1

        .promotion-info__icon
          color #7b7b85

        .promotion-info__unfold-icon
          stroke #7b7b85

    &__icon
      width 16px
      height 16px
      margin-left 8px
      color #cacad3
      transition color .3s

    &__unfold
      display flex
      align-items center
      color #5b40ff

    &__unfold-icon
      width 16px
      height 10px
      margin-left 2px
      transition transform .5s
      transform rotate(-180deg)
      stroke #cacad3

      &_down
        transition transform .5s
        transform rotate(0)

    &__opener-expand,
    &__opener-narrow
      margin-left 4px
      font-weight $font-weights.bold
      font-size 0
      color #7b7b85
      transition transform .5s, opacity 1s, font-size .3s
      opacity 0
      transform translateX(-50%)
      will-change transform, opacity, font-size

    &__list
      display flex
      justify-content space-between
      list-style none
      margin-left -30px
      margin-top 20px

    &__block
      flex-basis 460px
      margin-left 30px
</style>
